body {
  background: #FAF9F2;
}


.aws-btn {
  --button-default-height: 22px !important;
  --button-default-placeholder-width: 40px !important;
  --button-default-font-size: 5px !important;
  --button-default-border-radius: 2px !important;
  --button-horizontal-padding: 5px !important;
  --button-raise-level: 0px !important;
  --button-primary-color: #7b94bd !important;


}

.banner{
  position: fixed;
  display: block;
  margin-left: 0%;
  margin-right: auto;
  width: 14%;
  padding-top: 20%;

}

.flags {

  margin: 5px;
  margin-top: 15%;
  display: flex;
  flex-direction: row;
}

.App {
  min-height: 100vh;
  max-width: 100%;
  overflow-x: hidden;

}

.App img {
  max-width: 80%;
}

.App-logo {
  height: 150px;
  float: left;
  padding: 1px;
  border-radius: 100%;
  background-color: #fcf7e8;
}


.App-header {

  padding: 10px;
  color: #fcf7e8;
  width: 100%;
  background-image: url("../public/img/herfst.png");
  background-size: cover;
  background-position: center;
  position: fixed;
  z-index: 3;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {

  from {
    transform: rotate(-90deg);
  }
  to {
    transform: rotate(0deg)
  }

}

.logo {

  width: 500px;
  height: 200px;
  float: left;
  margin-top: 5px;
  margin-right: 30px;
  border-radius: 100%;
}

.button {
  height: 10px;
  float: right;
  border: none;
  padding: 0px;
  margin: 4px;
  text-decoration: none;
  background: none;
  font-family: sans-serif;
  cursor: pointer;
  text-align: center;


}

.but {
  font-family: 'Great Vibes', sans-serif;
}

nav {
  margin: 25px auto;
  justify-content: space-around;
  margin-bottom: 15px;
  float: right;
  text-align: right;

}


.App-header .container {
  margin: 10px;
  display: flex;
  justify-content: space-evenly;



}

.App-header .container2 {
  margin: 0px;
  display: flex;
  justify-content: space-evenly;


}

.App-header .container img {
  max-height: 50%;
  width: auto;
}


h2 {
  font-family: 'Great Vibes', cursive;
  font-size: 45px;
  color: #5a217a;
  text-align: left;
  font-weight: lighter;
  padding-left: 10%;
  text-shadow: 0 1px darkolivegreen;


}

h3 {
  font-family: 'Great Vibes', cursive;
  font-size: 24px;
  color: #129912;
  text-align: left;
  font-weight: lighter;



}

/* Landscape phones and down */
@media (max-width: 900px) {

  nav {
    margin: 5px auto;
    justify-content: space-around;
    margin-bottom: 15px;
    margin-right: 25px;

  }

  .banner{
    position: relative;
    display: flex;
    margin-left: 25%;
    margin-top: 50%;
    margin-right: auto;
    width: 50%;

  }


  .App-header .container {
    margin: 0 auto;
    display: flex;
  }

  .App-header .container img {
    max-height: 40%;
    max-width: 40%;
  }

  h2 {
    font-family: 'Great Vibes', cursive;
    font-size: 30px;
    text-align: left;
    font-weight: lighter;
    padding-left: 40%;
    text-shadow: 0 1px darkolivegreen;

  }

  .logo {

    height: 50px;
    margin-top: 15px;
    margin-right: 20px;
    border-radius: 100%;
  }

  .button {

    text-decoration: none;
    text-align: center;

  }


  .but {

    display: flex;
    flex-direction: row;
    font-size: 3vw;
    height: 18%;
    width: 75%;
  }

  .flags {

    margin: 1px;
    margin-right: 20px;
    display: flex;
    flex-direction: row;
    float: right;
  }

  .App-header .container {
    margin: 20px;
    display: inline-block;
    flex-direction: row;


  }

  .aws-btn {
    --button-default-height: 15px !important;
    --button-default-placeholder-width: 22px !important;
    --button-default-font-size: 5px !important;
    --button-default-border-radius: 5px !important;
    --button-horizontal-padding: 2px !important;
    --button-raise-level: 0px !important;


  }


  .App img {
    max-width: 100%;

  }

  .App-logo {
    height: 80px;
    float: left;
    padding: 1px;
  }
  .App-header {
    padding: 10px;
    color: #fcf7e8;
    top: 0;
    width: 100%;
    background-image: url("../public/img/herfst-klein.png");
    background-size: cover;
    background-position: center;

  }

}