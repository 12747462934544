.bg-white{
}




section {
    font-family: 'Montserrat',sans-serif;
    padding: 20px 25%;
    max-width: 70%;
    margin: auto auto;
    text-align: center;

}

.basistext{
    font-family: 'Montserrat',sans-serif;
    font-size: 15px;
    width:80%;
    text-align: center;
    margin: auto auto;
    margin-left: 15%;
    padding-top: 20%;


}

.aws-sld__content{
    background-color: #FBF3DA !important;
    border: 5px;
    border-color: #aaaaaa !important;
}



.column {
    float: left;
    width: 48%;
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    margin-left: 15%;
    padding-top: 5%;
    border-top: 2px solid #129912;

}

.video{

    height: auto;
    width: 80%;
    border:1px solid #80b380;
}



.column2 {
    margin-top: 0;

    padding-bottom: 200px;

    font-family: 'Montserrat',sans-serif;
    font-size: 14px;
    float: right;
    width: 35%;
}


.column img {

    width: auto !important;
    position: relative !important;
    border-radius: 3px;


}

.footer-logo{
    width: 20%;
    align:center;
}


/*phones and down */
@media (max-width: 900px) {

    section {
        font-family: 'Montserrat', sans-serif;
        font-weight: 450;
        padding: 20px 10%;
        max-width: 1000px;
        margin: auto auto;
        text-align: center;

    }


    .column {
        float: left;
        width: 85%;
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
        margin-left: 10%;
        padding-top: 5%;

    }


    .column2 {
        margin-top: 0;
        font-family: 'Montserrat',sans-serif;
        font-size: 12px;
        width: 85%;
    }

    .basistext{
        font-family: 'Montserrat',sans-serif;
        font-size: 14px;
        width:94%;
        text-align: center;
        margin: auto auto;
        padding-top: 75%;

    }

    .footer-logo{
        width: 30%;
    }

    .video{

        width: 100%;
    }
}