
.fotoo {
    padding: 15px 20%;
    border-radius:3px;
    padding-top: 30%;
}

.fotoo img {

    position: relative !important;
    object-fit: scale-down;
}

/* Landscape phones and down */
@media (max-width: 600px) {

    .fotoo {
        padding-top: 95%;
    }
}